<template>
  <header v-click-outside="hideSubMenu" class="relative isolate z-10 bg-white select-none">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
      <div class="flex lg:flex-1">
        <nuxt-link class="-m-1.5 p-1.5" to="/">
          <img class="h-16 w-auto invert" src="/logo.png"/>
        </nuxt-link>
      </div>

      <div class="flex lg:hidden">
        <actions-button size="sm" variant="secondary" @click="showMobileMenu = true">
          <icon name="mdi:hamburger"/>
        </actions-button>
      </div>

      <div class="hidden lg:flex lg:gap-x-12">
        <template v-for="menu_item in menuItems">
          <div v-if="menu_item.sub_menu">
            <button class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus:outline-none"
                    @click="selectMenuItem(menu_item)">
              {{ menu_item.label }}

              <icon name="material-symbols:keyboard-arrow-down-rounded"/>
            </button>

            <div v-if="menu_item.sub_menu.show"
                 class="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
              <div class="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                <div v-for="sub_menu_item in menu_item.sub_menu.items"
                           :to="sub_menu_item.to"
                           class="group relative rounded-lg p-6 text-sm leading-6 hover:bg-gray-50 cursor-pointer">
                  <div class="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <icon :name="sub_menu_item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"/>
                  </div>

                  <span class="mt-3 block font-semibold text-gray-900">
                    {{ sub_menu_item.name }}
                  </span>

                  <p class="mt-1 text-gray-600">
                    {{ sub_menu_item.description }}
                  </p>
                </div>
              </div>

              <div class="bg-gray-50">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                  <div class="flex justify-evenly items-center divide-x divide-gray-900/5 border-x border-gray-900/5">
                    <template v-for="additional_item in menu_item.sub_menu.additional_items">
                      <a v-if="additional_item.href" :href="additional_item.href"
                         class="flex items-center justify-center w-full gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                         rel="noopener noreferrer" target="_blank">
                        <icon :name="additional_item.icon" class="text-gray-500"/>
                        {{ additional_item.label }}
                      </a>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <nuxt-link v-else :to="menu_item.to" class="text-sm font-semibold leading-6 text-gray-900">
            {{ menu_item.label }}
          </nuxt-link>
        </template>
      </div>

      <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end space-x-4">
        <nuxt-link class="text-sm font-semibold leading-6 text-gray-900" to="/login">
          Log in
        </nuxt-link>

        <nuxt-link class="btn-primary-sm" to="/register">
          Register
        </nuxt-link>
      </div>
    </nav>

    <div class="lg:hidden" v-if="showMobileMenu">
      <div class="fixed inset-0 z-10"></div>

      <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <nuxt-link class="-m-1.5 p-1.5" to="/">
            <img class="h-16 w-auto invert" src="/logo.png"/>
          </nuxt-link>

          <actions-close @close="hideMobileMenu"/>
        </div>

        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <template v-for="menu_item in menuItems">
                <div v-if="menu_item.sub_menu" class="-mx-3">
                  <button
                      class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      @click="selectMenuItem(menu_item)">
                    {{ menu_item.label }}

                    <icon name="material-symbols:keyboard-arrow-down-rounded"/>
                  </button>

                  <div v-if="menu_item.sub_menu.show" class="mt-2 space-y-2">
                    <nuxt-link v-for="sub_menu_item in menu_item.sub_menu.items" :to="sub_menu_item.to"
                               class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      <div class="flex flex-col items-start space-y-1">
                        <div class="flex items-center space-x-2">
                          <icon :name="sub_menu_item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"/>

                          <span>
                            {{ sub_menu_item.name }}
                          </span>
                        </div>

                        <span class="text-gray-600">
                          {{ sub_menu_item.description }}
                        </span>
                      </div>
                    </nuxt-link>
                  </div>
                </div>

                <nuxt-link v-else
                           :to="menu_item.to"
                           class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  {{ menu_item.label }}
                </nuxt-link>
              </template>
            </div>

            <div class="py-6">
              <nuxt-link
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  to="/login">
                Log in
              </nuxt-link>

              <nuxt-link
                  class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  to="/register">
                Register
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import {FEATURES} from "~/helpers/services.js"
import {infoStore} from "~/stores/info.js";

const route = useRoute()

const platforms = computed(() => infoStore().platforms)

const menuItems = ref([
  {
    label: 'Platforms',
    sub_menu: {
      show: false,
      items: platforms.value,
      additional_items: [
        {
          href: 'https://www.youtube.com/playlist?list=PL3ZhWMazGi9INhveY9cB6RqWJq9TK0BT-&playnext=1&index=1',
          icon: 'material-symbols:play-circle-rounded',
          label: 'Watch demo',
        },

        {
          href: 'https://roadmap.thelisting.app/tabs/1-under-consideration/submit-idea',
          icon: 'material-symbols-light:mark-unread-chat-alt-rounded',
          label: 'Request a new platform',
        },
      ],
    },
  },

  {
    label: 'Features',
    sub_menu: {
      show: false,
      items: FEATURES,
      additional_items: [
        {
          href: 'https://www.youtube.com/playlist?list=PL3ZhWMazGi9INhveY9cB6RqWJq9TK0BT-&playnext=1&index=1',
          icon: 'material-symbols:play-circle-rounded',
          label: 'Watch demo',
        },

        {
          href: 'https://roadmap.thelisting.app/tabs/1-under-consideration/submit-idea',
          icon: 'material-symbols-light:mark-unread-chat-alt-rounded',
          label: 'Request a new feature',
        },
      ],
    },
  },

  {
    label: 'Pricing',
    to: '/pricing',
  },
])

const showMobileMenu = ref(false)

onMounted(() => {
  window.addEventListener('keydown', close)
})

onUnmounted(() => {
  window.removeEventListener('keydown', close)
})

function close(event) {
  if (event.key === 'Escape') {
    hideAllSubMenus()
  }
}

function selectMenuItem(menu_item) {
  menu_item.sub_menu.show = !menu_item.sub_menu.show

  hideSubMenu(menu_item)
}

function hideSubMenu(except) {
  menuItems.value.forEach(menu_item => {
    if (menu_item.sub_menu) {
      if (except && menu_item.label !== except.label) {
        menu_item.sub_menu.show = false
      }
    }
  })
}

function hideAllSubMenus() {
  menuItems.value.forEach(menu_item => {
    if (menu_item.sub_menu) {
      menu_item.sub_menu.show = false
    }
  })
}

function hideMobileMenu() {
  showMobileMenu.value = false
}

watch(() => route.path, () => {
  hideAllSubMenus()
  hideMobileMenu()
})

watch(platforms, () => {
  menuItems.value[0].sub_menu.items = platforms.value
})
</script>

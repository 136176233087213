<template>
  <footer class="bg-white">
    <div class="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
      <div class="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24 space-y-2 md:space-y-0">
        <p class="mt-8 text-xs leading-5 text-gray-700 md:mt-0">
          &copy; {{ new Date().getFullYear() }} The Listing App. All rights reserved.
          <br>
          Made with ❤️ by
          <a class="link" href="https://mepelqen.al">
            Mepelqen Software Agency
          </a>
        </p>

        <div class="flex items-center">
          <nuxt-link to="/privacy-policy" class="text-gray-700 hover:text-gray-800">
            Privacy Policy
          </nuxt-link>

          <span class="mx-3 text-gray-700">|</span>

          <nuxt-link to="/terms-of-service" class="text-gray-700 hover:text-gray-800">
            Terms of Service
          </nuxt-link>
        </div>

        <div class="flex">
          <span class="mr-2">
            Follow us on:
          </span>

          <div class="space-x-3">
            <template v-for="platform in platforms">
              <a v-if="platform.follow_us_links.length" :key="platform.id"
                 :href="platform.follow_us_links[0]" class="text-gray-400 hover:text-gray-500" target="_blank">
                <icon :name="platform.icon" class="h-6 w-6"/>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import {infoStore} from "~/stores/info.js"

const platforms = computed(() => infoStore().platforms)
</script>
